import {
  readAccount,
  resendAlertConfirmationEmail,
  updateAlertsEmail,
} from './account'

import {
  createAlert,
  deleteAlert,
  readAlerts,
  unsubscribeAlert,
  updateAlert,
} from './alert'

import {
  claimArticleToOrcid,
  fetchAnnotations,
  fetchAnnotationsCount,
  fetchArticles,
  fetchAuthorProfileList,
  fetchCitationsExport,
  fetchCitingArticles,
  fetchDatalinks,
  fetchFigures,
  fetchFulltextHtml,
  fetchFulltextXmlFromRepo,
  fetchHypothesis,
  fetchLinkBackAnnotation,
  fetchNbkID,
  fetchPreprintFiles,
  fetchReferences,
  fetchSimilarArticles,
  fetchScite,
  fetchUnpaywallLink,
  fetchYearlyCitations,
  fetchEvaluations,
} from './article'

import { fetchArticleStatus } from './article-status'

import {
  fetchAuthorAff,
  fetchAuthorProfile,
  fetchAuthorPublications,
  fetchAuthorPublicOrcidPublications,
  fetchAuthorYearlyCitations,
  fetchSuggestedAuthors,
} from './author'

import { fetchBlog, fetchTechBlog } from './blog'

import { sendFeedback } from './feedback'

import {
  fetchAllGrants,
  fetchCitationsByGrantData,
  fetchGrant,
  fetchGrants,
  fetchGristSuggest,
  fetchYearlyCitationsByGrantData,
  fetchYearlyCountsByGrantData,
} from './grant'

import { saveRedirectTarget, saveSearch } from './history'

import { fetchLoggedInOrcidId } from './orcid'

import { fetchPrivacyNotice } from './privacy-notice'

import {
  fetchAllSearchResults,
  fetchContentTypes,
  fetchYearlyCounts,
  fetchSearchResults,
  fetchSnippets,
  fetchSuggestions,
} from './search'

import { fetchJournals, fetchAuthors } from './advanceSearch'

import { fetchStats } from './stats'

import { transformXml } from './xslt'

// some types require payload
const send = async (
  url,
  type = 'GET',
  payload = null,
  customParams = null,
  getJqXhrObject = false
) => {
  let params = { type, url }
  if (payload) {
    params.contentType = 'application/json; charset=utf-8'
    params.dataType = 'json'
    params.data = JSON.stringify(payload)
  }
  if (customParams) {
    params = { ...params, ...customParams }
  }
  // eslint-disable-next-line
  const ajax = $.ajax
  return getJqXhrObject
    ? ajax(params).then((data, textStatus, jqXHR) => ({
        data,
        textStatus,
        jqXHR,
      }))
    : ajax(params)
}

const encodeQuery = (query) => {
  return encodeURIComponent(query).replace(/%3A/g, ':').replace(/%2B/g, '+')
}

const checkRedirect = (response) => {
  if (response && response['Error'] === 'No account found') {
    if (
      window.confirm('Your session has expired. Do you want to sign in again?')
    ) {
      location.href = '/accounts/login'
    } else {
      location.reload()
    }
  }
}

export {
  // general
  send,
  encodeQuery,
  checkRedirect,
  // account
  readAccount,
  resendAlertConfirmationEmail,
  updateAlertsEmail,
  // alert
  createAlert,
  deleteAlert,
  readAlerts,
  unsubscribeAlert,
  updateAlert,
  // article
  claimArticleToOrcid,
  fetchAnnotations,
  fetchAnnotationsCount,
  fetchArticles,
  fetchAuthorProfileList,
  fetchBlog,
  fetchCitationsExport,
  fetchCitingArticles,
  fetchDatalinks,
  fetchFigures,
  fetchFulltextHtml,
  fetchFulltextXmlFromRepo,
  fetchHypothesis,
  fetchLinkBackAnnotation,
  fetchNbkID,
  fetchPreprintFiles,
  fetchReferences,
  fetchSimilarArticles,
  fetchScite,
  fetchTechBlog,
  fetchUnpaywallLink,
  fetchYearlyCitations,
  fetchEvaluations,
  // article status
  fetchArticleStatus,
  // author
  fetchAuthorAff,
  fetchAuthorProfile,
  fetchAuthorPublications,
  fetchAuthorPublicOrcidPublications,
  fetchAuthorYearlyCitations,
  fetchSuggestedAuthors,
  //feedback
  sendFeedback,
  // grant
  fetchAllGrants,
  fetchCitationsByGrantData,
  fetchGrant,
  fetchGrants,
  fetchGristSuggest,
  fetchYearlyCitationsByGrantData,
  fetchYearlyCountsByGrantData,
  // history
  saveRedirectTarget,
  saveSearch,
  // orcid
  fetchLoggedInOrcidId,
  // privacy notice
  fetchPrivacyNotice,
  // search
  fetchAllSearchResults,
  fetchContentTypes,
  fetchYearlyCounts,
  fetchSearchResults,
  fetchSnippets,
  fetchSuggestions,
  // stats
  fetchStats,
  // xslt
  transformXml,
  // advance search
  fetchJournals,
  fetchAuthors,
}
