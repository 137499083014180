import {
  EPMC_API_ROOT,
  EPMC_API_GET_ROOT,
  EPMC_API_FT_REPO_ROOT,
  EPMC_ROOT,
} from '@/config'
import { send, fetchSearchResults, transformXml } from '@/api'

export function claimArticleToOrcid(citation, checkAuthorizationOnly = false) {
  const url =
    window.location.hostname == 'localhost'
      ? 'http://localhost:8088/api/'
      : EPMC_API_ROOT
  return send(
    url + 'articleclaim' + (checkAuthorizationOnly ? '?checkOnly' : ''),
    'POST',
    citation,
    { dataType: 'text' },
    true
  )
}

export function fetchAnnotations(source, id, abstractOnly, provider) {
  let req = EPMC_API_GET_ROOT + 'annotationsApi?'
  req += 'source=' + source
  req += '&id=' + id
  req += abstractOnly ? '&section=Abstract' : ''
  req += provider ? '&provider=' + encodeURIComponent(provider) : ''
  return send(req)
}

export function fetchAnnotationsCount(source, id) {
  const req = `${EPMC_API_GET_ROOT}annotationCount?id=${id}&source=${source}`
  return send(req)
}

export function fetchArticles(params) {
  const { articles, format, pageSize, resultType, ws } = params
  const query = articles
    .map((article) => {
      if (article.source.toUpperCase() === 'PMC') {
        if (article.id.toUpperCase().indexOf('PMC') === -1) {
          return 'PMCID:PMC' + article.id
        } else {
          return 'PMCID:' + article.id
        }
      } else if (article.source.toUpperCase() === 'NBK') {
        if (article.id.toUpperCase().indexOf('NBK') === -1) {
          return 'BOOK_ID:NBK' + article.id
        } else {
          return 'BOOK_ID:' + article.id
        }
      } else if (article.source.toUpperCase() === 'MID') {
        return 'AUTH_MAN_ID:' + article.id
      } else {
        return '(EXT_ID:' + article.id + ' AND SRC:' + article.source + ')'
      }
    })
    .join(' OR ')
  return fetchSearchResults({ format, pageSize, query, resultType, ws })
}

export function fetchAuthorProfileList(data) {
  return send(EPMC_API_GET_ROOT + 'orcid/profile', 'POST', data, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

export function fetchCitationsExport(data) {
  return send(EPMC_API_GET_ROOT + '/export', 'POST', data, {
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
    },
    xhrFields: {
      withCredentials: true,
    },
  })
}

export function fetchCitingArticles(source, id) {
  return fetchSearchResults({
    pageSize: 5,
    query: 'CITES:' + id + '_' + source,
    ref: true,
  })
}

/**export function fetchDatalinks(source, id, options) {
  const { tags, category } = options
  let req = EPMC_API_GET_ROOT + '/datalinks?'
  req += 'source=' + source
  req += '&id=' + id
  req += tags ? '&tags=' + encodeURIComponent(tags) : ''
  req += category ? '&category=' + encodeURIComponent(category) : ''
  const r = send(req)
	console.log(r)
	return r
}**/
export function fetchDatalinks(source, id, options) {
  return new Promise((resolve, reject) => {
    const { tags, category } = options
    let req = EPMC_API_GET_ROOT + '/datalinks?'
    req += 'source=' + source
    req += '&id=' + id
    req += tags ? '&tags=' + encodeURIComponent(tags) : ''
    req += category ? '&category=' + encodeURIComponent(category) : ''

    const timeoutId = setTimeout(() => {
      reject(new Error('Request timed out'))
    }, 5000)

    send(req)
      .then((response) => {
        clearTimeout(timeoutId)
        resolve(response)
      })
      .catch((error) => {
        clearTimeout(timeoutId)
        reject(error)
      })
  })
}

export function fetchFigures(ftid) {
  const req = EPMC_API_GET_ROOT + '/inlineImagesApi?ftid=' + ftid
  return send(req)
}

export function fetchFulltextHtml(fulltextId) {
  let ssnexLite = null
  if (
    fulltextId.startsWith('PMC') &&
    (ssnexLite = loadSsnexLiteXML(fulltextId))
  ) {
    return new Promise((resolve) => {
      renderAsPMC(fulltextId, resolve, ssnexLite, false)
    })
  } else if (
    fulltextId.startsWith('PMC') ||
    fulltextId.startsWith('NBK') ||
    fulltextId.startsWith('mid')
  ) {
    const renderType =
      fulltextId.startsWith('PMC') || fulltextId.startsWith('mid')
        ? 'articlerender'
        : 'bookrender'
    const key =
      fulltextId.startsWith('PMC') || fulltextId.startsWith('mid')
        ? 'PMCID'
        : 'NBKID'
    if (fulltextId.startsWith('mid')) {
      fulltextId = 'mid&MANUSCRIPTID=' + fulltextId.split('/')[1]
    }
    const req = EPMC_API_ROOT + renderType + '?' + key + '=' + fulltextId
    return new Promise((resolve) => {
      send(req).then((response) => {
        let error = ''

        if (fulltextId.startsWith('PMC')) {
          if (typeof response === 'object') {
            error = response.querySelector('http-status[value="404"]')
          } else if (typeof response === 'string') {
            const div = document.createElement('div')
            div.innerHTML = response
            error =
              div.querySelector('http-status[value="404"]') ||
              div.querySelector('http-status[value="503"]')
          }
        }

        // render as preprint if it has error
        if (error) {
          renderAsPreprint(fulltextId, resolve, response)
        } else {
          resolve(response)
        }
      })
    })
  } else if (fulltextId.startsWith('PPR')) {
    return new Promise((resolve) => {
      renderAsPreprint(fulltextId, resolve)
    })
  } else if (fulltextId.startsWith('M')) {
    return new Promise((resolve) =>
      fetchFulltextXml(fulltextId, 'CTX').then((xml) =>
        transformXml(xml, 'jats2html').then((resultDocument) =>
          resolve(resultDocument)
        )
      )
    )
  }
}

function renderAsPreprint(fulltextId, resolve, errorMessage) {
  Promise.all([
    fetchFulltextXmlFromRepo(fulltextId),
    fetchPreprintFiles(fulltextId),
  ])
    .then((response) => {
      transformXml(response[0], 'jats2html').then((preprintDocument) => {
        resolve({ preprintDocument, files: response[1].files })
      })
    })
    .catch(() => {
      if (errorMessage) {
        resolve(errorMessage)
      }
    })
}

function renderAsPMC(fulltextId, resolve, pmcXml) {
  const xsl = 'jats2html_pmc'
  Promise.all([fetchPreprintFiles(fulltextId)])
    .then((response) => {
      transformXml(pmcXml, xsl).then((pmcDocument) => {
        resolve({ pmcDocument, files: response[0].files })
      })
    })
    .catch((errorMessage) => {
      if (errorMessage) {
        resolve(errorMessage)
      }
    })
}

function fetchFulltextXml(fulltextId) {
  const req =
    EPMC_API_GET_ROOT +
    'fulltextXML?id=' +
    fulltextId +
    '&source=' +
    fulltextId.substring(0, 3)
  return send(req)
}

export function fetchFulltextXmlFromRepo(fulltextId) {
  const req = EPMC_API_ROOT + 'fulltextXmlRepo?ft_id=' + fulltextId
  return send(req)
}

export function fetchHypothesis(pmid) {
  const req =
    'https://hypothes.is/api/search?_separate_replies=true&group=NMb8iAjd&uri=' +
    encodeURIComponent('https://europepmc.org') +
    encodeURIComponent('/abstract/MED/') +
    pmid
  return send(req)
}

export function fetchLinkBackAnnotation(source, id, annotationId) {
  const req =
    EPMC_API_GET_ROOT +
    'annotationsLinkBack?' +
    'source=' +
    source +
    '&id=' +
    id +
    '&annotationId=' +
    annotationId
  return send(req)
}

export function fetchNbkID(bookPath, toc) {
  const params = bookPath + (toc ? '?report=toc' : '')
  const req =
    EPMC_API_ROOT + 'bookrender?bookPath=' + encodeURIComponent(params)
  return send(req)
    .then((response) => {
      // wrapper method to mock the relevant test:
      return serialiseBook(toc, response)
    })
    .catch((e) => {
      if (e.status === 200 && e.responseText) {
        return e.responseText
      } else {
        return ''
      }
    })
}

function serialiseBook(toc, response) {
  return toc ? new XMLSerializer().serializeToString(response) : response
}

export function fetchPreprintFiles(fulltextId) {
  const req =
    EPMC_API_FT_REPO_ROOT +
    (fulltextId.startsWith('PPR') ? 'pprId' : 'pmcId') +
    '=' +
    fulltextId +
    '&type=METADATA'
  return send(req)
}

export function fetchReferences(source, id, page = 1) {
  let req = EPMC_API_GET_ROOT + '/references?'
  req += 'source=' + source
  req += '&id=' + id
  req += '&page=' + page
  req += '&pageSize=10'
  return send(req)
}

export function fetchSimilarArticles(pmid) {
  const req = EPMC_API_GET_ROOT + 'similarArticles?id=' + pmid
  return send(req)
}

export function fetchScite(doi) {
  const req = 'https://api.scite.ai/tallies/' + doi
  return send(req)
}

export function fetchUnpaywallLink(doi) {
  const req = 'https://api.oadoi.org/v2/' + doi + '?email=literature@ebi.ac.uk'
  return send(req)
}

export function fetchYearlyCitations(source, id) {
  const req =
    EPMC_API_ROOT +
    'citation/getYearlyCitationsByExtId?src=' +
    source +
    '&extId=' +
    id
  return send(req)
}

export function fetchEvaluations(source, id) {
  let req = EPMC_API_GET_ROOT + 'evaluation?'
  req += 'source=' + source
  req += '&id=' + id
  return send(req)
}

function loadSsnexLiteXML(fulltextId) {
  const pmcId = fulltextId.replace(/^PMC/, '').trim()
  const ssnexLiteXmlName = pmcId + '.ssnexlite.xml'
  //for local testing - to be removed when deployed
  // const req = EPMC_ROOT + 'doc/' + ssnexLiteXmlName
  //uncomment when deployed
  const req = EPMC_ROOT + 'docs/ssnexlite/' + ssnexLiteXmlName
  const xhttp = new XMLHttpRequest()
  xhttp.open('GET', req, false)
  xhttp.send()
  if (
    xhttp.status === 200 &&
    xhttp.getResponseHeader('Content-Type').includes('xml')
  ) {
    // console.log('xhttp.responseXML:' + xhttp.responseXML)
    return xhttp.responseXML
  } else {
    // console.error('Failed to load XML:', xhttp.statusText)
    return false
  }
}
