import { isBeta } from './sessionStorage'
import {
  PLACES_TO_SEARCH,
  ARTICLE_SECTION_OPTIONS,
  //PUB_TYPE_PUBMED_NCBI_OPTIONS,
} from '@/config'

const productionSearchPath = 'search'
const betaSearchPath = 'betaSearch'

const placesToSearch = PLACES_TO_SEARCH
const articleSectionOptions = ARTICLE_SECTION_OPTIONS
//const publicationTypePubmedNcbiOptions = PUB_TYPE_PUBMED_NCBI_OPTIONS
const identifiersToBeHighlighted = [
  'PUB_TYPE',
  'JOURNAL',
  'SRC',
  'HAS_BOOK',
  'PPR',
  'DOI',
  'PMCID',
  'EXT_ID',
  'ISSN',
  'ISBN',
  'PUBLISHER',
]

export function isValidSearchPath(path) {
  return path === productionSearchPath || path === betaSearchPath
}

export function getProductionSearchPath() {
  return productionSearchPath
}

export function getBetaSearchPath() {
  return betaSearchPath
}

export function getSearchPath() {
  return isBeta() ? betaSearchPath : productionSearchPath
}

export function constructHtmlForQuery(item) {
  const { query, term, id } = item
  let html = query.articleIdSearchQuery || query
  identifiersToBeHighlighted.map((identifier) => {
    if (html.includes(`${identifier}:`)) {
      html = html.replaceAll(`${identifier}:`, createSpanStyle(identifier))
    }
  })
  if (html.includes('TITLE_ABS:')) {
    html = html.replace('TITLE_ABS:', createSpanStyle('TITLE_ABS'))
  } else
    placesToSearch.map(({ value }) => {
      if (value && html.includes(value)) {
        html = html.replace(`${value}:`, createSpanStyle(value))
      }
    })
  articleSectionOptions.map(({ value }) => {
    if (html.includes(value)) {
      html = html.replace(`${value}:`, createSpanStyle(value))
    }
  })
  /*if (html.includes('PUB_TYPE:')) {
    html = html.replaceAll('PUB_TYPE:', createSpanStyle('PUB_TYPE'))
  } */
  if (term)
    html = html.replaceAll(
      `(${term})`,
      `<span>(<span data-internalid=${id}>${term}</span>)</span>`
    )
  return html
}

function createSpanStyle(value) {
  return `<span style='color: #969696;'>${value}:</span>`
}
