export function getAllPubLink(grant) {
  const funders = require('@/config').FUNDERS
  const funder = funders.find((f) => f.name === grant['Funder']['Name'])
  const funderName = funder ? funder.value[0] : grant['Funder']['Name']
  let link = `GRANT_AGENCY_ID:"${grant['Id']}_agency_${funderName}"`

  if (grant['Alias']) {
    link += ' OR '
    link += Array.isArray(grant['Alias'])
      ? grant['Alias']
          .map((a) => `GRANT_AGENCY_ID:"${a}_agency_${funderName}"`)
          .join(' OR ')
      : `GRANT_AGENCY_ID:"${grant['Alias']}_agency_${funderName}"`
  }

  return link
}

export function getAllFTLink(grant) {
  return '(' + getAllPubLink(grant) + ') AND IN_EPMC:y'
}

export function getUiType() {
  let type = ''
  if (location.pathname.startsWith('/grantfinder/results')) {
    if (location.search.includes('cat=COVID-19')) {
      type = 'covid-19-search'
    } else {
      type = 'epmc-search'
    }
  } else if (location.pathname.startsWith('/grantfinder/grantdetails')) {
    type = 'grant-details'
  } else {
    type = 'epmc-form'
  }
  return type
}

export function getOrcid(person) {
  let orcid = ''

  if (person['Alias']) {
    if (person['Alias'].length) {
      const a = person['Alias'].find((a) => a['@Source'] === 'ORCID')
      if (a) {
        orcid = a['$']
      }
    } else {
      const a = person['Alias']
      if (a['@Source'] && a['@Source'] === 'ORCID') {
        orcid = a['$']
      }
    }
  }
  orcid = orcid.toUpperCase()

  return orcid
}

export function formatInstituteList(list) {
  let institutionList = list ? (Array.isArray(list) ? list : [list]) : []
  // console.log(institutionList)
  //collect unique RorOfficialNames
  const RorOfficialNames = [
    ...new Set(
      institutionList.map((institution) =>
        institution.RORID ? institution.RorOfficialName.toLowerCase() : null
      )
    ),
  ]
  //filter out institutionList which does not contain matching Name and RorOfficialName
  institutionList = institutionList.filter((institution) => {
    if (
      institution.RorOfficialName ||
      (institution.Name &&
        !RorOfficialNames.includes(institution.Name.toLowerCase()))
    )
      return institution
  })
  //have only unique "RorOfficialName" values
  institutionList = [
    ...new Map(
      institutionList.map((institution) => [
        institution.RORID
          ? institution['RorOfficialName']
          : institution['Name'],
        institution,
      ])
    ).values(),
  ]
  return institutionList
}
